import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import SignInBySMSForm from "./SignInBySMSForm";
import {actions as authActions} from "../../../actions/authActions";
import {useDispatch} from "react-redux";
import SignInByEmailForm from "./SignInByEmailForm";
import {LoginMode, Oauth2Provider} from "../../../models/auth/AuthModel";
import PropTypes from "prop-types";
import Oauth2SignIn from "../oauth2/oauth2SignIn";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '15px',
    marginTop: 10,
  },
  button: {
    backgroundColor: '#000',
    "&:hover": {
      backgroundColor: '#626161',
    },
  },
  buttonText: {
    color: '#fff',
  },
  changeLoginMode: {
    marginTop: 10,
  },
  changeLoginModeButton: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  changeLoginModeButtonText: {
    color: "#000",
    "&:hover": {
      color: "#4f4f4f",
    }
  }
}));


const SelectSignInType = ({ loginMode, setLoginMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const checkLoginMode = (mode) => {
    return loginMode === mode;
  }

  const onHandlerSMSCode = (data) => {
    dispatch(authActions.requestSmsCode(data))
  }

  const onHandlerEmailCode = (data) => {
    dispatch(authActions.requestEmailCode(data))
  }

  const onHandlerOAuth2Plock = () => {
    setLoginMode(LoginMode.oauth2)
    dispatch(authActions.requestOauth2Url(Oauth2Provider.plockCard))
  }

  return(
    <div>
      {checkLoginMode(LoginMode.select) && <div>
        <div>
          {t('loginSignIn.selectLoginMethod')}
        </div>
        <div className={classes.buttonGroup}>
          <Button className={classes.button} onClick={() => setLoginMode(LoginMode.email)}>
            <span className={classes.buttonText}>{t('loginSignIn.loginMethod.email')}</span>
          </Button>
          {/*<Button className={classes.button} onClick={() => setLoginMode(LoginMode.sms)}>*/}
          {/*  <span className={classes.buttonText}>{t('loginSignIn.loginMethod.sms')}</span>*/}
          {/*</Button>*/}
          <Button className={classes.button} onClick={() => onHandlerOAuth2Plock()}>
            <span className={classes.buttonText}>{t('loginSignIn.loginMethod.plockCard')}</span>
          </Button>
        </div>
      </div>}
      {checkLoginMode(LoginMode.sms) && <SignInBySMSForm onSubmit={onHandlerSMSCode}/>}
      {checkLoginMode(LoginMode.email) && <SignInByEmailForm onSubmit={onHandlerEmailCode}/>}
      {checkLoginMode(LoginMode.oauth2) && <Oauth2SignIn />}
      {!checkLoginMode(LoginMode.select) && <div className={classes.changeLoginMode}>
        <Button className={classes.changeLoginModeButton} onClick={() => setLoginMode(LoginMode.select)}>
          <span className={classes.changeLoginModeButtonText}>{t('loginSignIn.changeLoginMethod')}</span>
        </Button>
      </div>}
    </div>
  )

}

SelectSignInType.propTypes = {
  loginMode: PropTypes.string.isRequired,
  setLoginMode: PropTypes.func.isRequired,
};

export default SelectSignInType
